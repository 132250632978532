<script>
import { ref, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import { needLoading } from '@/reactive'

import { http } from '@/http'

export default {
  setup() {
    let bindName = ref('')
    let bindBankName = ref('')
    let bindAccountNo = ref('')
    let bindBranchName = ref('')
    let bankType = ref('')
    let checkTimer = null
    let router = useRouter()
    let route = useRoute()
    // let accountName = ref('')
    // let accountName = ref('')
    function onSubmit(fields) {
      if(!fields.bindBankName || !bankType.value) {
        return false
      }
      if(route.query.card) {
        bindBankCard('/?cmd=com.xqxc.api.pigear.pay.modifyBindBankCard', { ...fields, bindBankType: bankType.value })
      }
      else {
        bindBankCard('/?cmd=com.xqxc.api.pigear.pay.BankCardService.bindBankCard', { ...fields,bindBankType: bankType.value })
      }
    }

    function receiveRealName() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerTaxCommissionRecordService.getTaxCommissionRecord')
        .then(res => {
          if(res.result) {
            bindName.value = res.result.realName
          }
        })
    }

    function bindBankCard(url, data) {
      http.post(url,  {
        ...data
      }).then(res => {
        if(res.result) {
          Toast.success('银行卡绑定成功')
          router.push('/trust/result')
        }
      })
    }

    function bankCardCheck() {
      needLoading.value = true
      http.post('/?cmd=com.xqxc.api.pigear.pay.BankCardService.bankCardCheck', {
        bankCardNo: bindAccountNo.value
      }).then(res => {
        if(res.result) {
          bindBankName.value = res.result.bankName
          bankType.value = res.result.bankType
        }
        
      })
    }

    onMounted(receiveRealName)

    // function modifyBindBankCard() {
    //   http.post('/?cmd=com.xqxc.api.pigear.pay.modifyBindBankCard', {

    //   })
    // }

    // watch(() => bindAccountNo.value, (a, b) => {
    //   clearTimeout(checkTimer)
    //   if(a.length >= 12) {
    //     checkTimer = setTimeout(bankCardCheck, 100)
    //   }
    // })

    function handleBankCardCheck() {
      if(bindAccountNo.value.length > 12)
        bankCardCheck()
    }
  

    return {
      bindName,
      bindBankName,
      bindAccountNo,
      bindBranchName,
      bankType,
      onSubmit,
      handleBankCardCheck
    }
  }
}
</script>
<template>
  <div class="page">
    <div class="page-title">
      <h3>绑定银行卡</h3>
    </div>
    <van-form @submit="onSubmit">
      <div class="box">
        <!-- <ul>
        <li><span>持卡人</span><div><input placeholder="请输入持卡人姓名"/></div></li>
        <li><span>银行卡号</span><div><input placeholder="请输入银行卡卡号" /></div></li>
        <li><span>银行名称</span><div><input placeholder="请输入银行名称" /></div></li>
        <li><span>开户支行</span><div><input placeholder="请输入开户支行" /></div></li>
      </ul> -->
        <van-field
          v-model="bindName"
          name="bindName"
          required
          label="持卡人"
          placeholder="请输入持卡人姓名"
        />
        <van-field
          v-model="bindAccountNo"
          name="bindAccountNo"
          type="number"
          @blur="handleBankCardCheck"
          required
          label="银行卡号"
          placeholder="请输入银行卡卡号"
          :rules="[{
            required: true,
            message: '请输入银行卡卡号'
          }, {
            pattern: /^[1-9]\d{12,18}$/,
            message: '请输入正确的银行卡号'
          }]"
        />
        <van-field
          v-model="bindBankName"
          name="bindBankName"
          required
          label="银行名称"
          placeholder="请输入银行名称"
        />
        <van-field
          v-model="bindBranchName"
          name="bindBranchName"
          required
          label="开户支行"
          placeholder="请输入开户支行"
        />
      
      </div>
      <div class="box" >
        <p style="font-size:.24rem; color:#E61F3E">请绑定持卡人本人的银行卡，且支行务必填写正确</p>

        <div style="margin: 20px 0 0;">
          <van-button block type="primary" native-type="submit">下一步</van-button>
        </div>
      </div>
    </van-form>
    
  </div>
</template>
<style scoped>
    .box { font-size: .28rem; padding: 0 .4rem; background-color: #fff;}
    .box li { border-bottom: 1px solid #eee; height:.85rem; display:table; width:100% }
    .box li:last-child { border-bottom:none }
    .box li span { display: table-cell;  vertical-align: middle; width:2.3rem }
    .box li input { width: 100%; border:none }
    .box li div { display: table-cell;  vertical-align: middle}
    .box:last-child {  margin-top:.3rem; background-color:transparent }
</style>